import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  required,
  useCreatePath,
  useNotify,
  useRedirect,
} from 'react-admin';

const Aside = () => {
  const createPath = useCreatePath();
  const clauses = [
    'Paste the meeting link and add the Date/Time of the meeting (leave Date/Time field blank to join a meeting immediately)',
    <>Alternatively send a meeting invite to <a href="mailto:yakbot@yakbit.ai">yakbot@yakbit.ai</a> to schedule the bot to join your meeting. It must be from the same email address used for this account</>,
    <>Scheduled meetings can be viewed from the <Link to={createPath({ resource: 'meetings', type: 'list' }) }>Meetings</Link> section in the menu</>,
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Box sx={{ width: {xs: 'auto', lg:'40%'}, margin: '1em 1em 100px 2em' }}>
      <Typography variant="h6">Instructions</Typography>
      <ol>{listItems}</ol>
    </Box>
  )
};

const RecallCreate: FC = (props) => {
  const notify = useNotify();
  // const redirect = useRedirect();

  const onSuccess = (data: any) => {
    // notify('Bot sent to meeting. Please allow the bot to enter and record the meeting. Once the meeting is complete, please book a session to discuss your results.', { autoHideDuration: 0 });
    notify('Bot sent to meeting. Please allow the bot to enter and record the meeting. Once the meeting is complete, please book a session to discuss your results.', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 60000, multiLine: true, type: 'success' });
    // redirect('/book-coaching');
  };

  return (
    <Create resource="recall" title="Schedule a Meeting" aside={<Aside />} sx={{
    '& .RaCreate-main': {
      flexDirection: {xs: 'column', md: 'row'},
    },
    }} mutationOptions={{ onSuccess }} {...props}>
    {/*
    <Create mutationOptions={{ onSuccess }} {...props}>
    */}
      <SimpleForm>
        <TextInput source="url" label="Meeting Link / Url"/>
        <DateTimeInput source="date_time" label="Date/Time"/>
      </SimpleForm>
    </Create>
  );
}

export default RecallCreate;

